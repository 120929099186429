import { ApiService } from '../../../services/api.service';
import { PartnerModel } from '../../../model/PartnerModel';
//import { IRecipients } from '../../../DTO/Common';
import { IUser, IUserRequest } from '../../../model';

export const LOGIN_URL = "api/auth/login";
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

export const ME_URL = "api/me";

// export class LoginApiService {
// constructor(private _apiService: ApiService) { }
export async function login(username: string, password: string) {
  return await new ApiService().post('auth/signin', { username, password });
  //}
}

export async function getUserByToken(userid: any) {
  // let adminid = 1;
  //debugger;7
      let user: any = await new ApiService().get('user/getUserById', { userid });
      //debugger;
  return user?.content;
}



export async function register(user: PartnerModel) {
  return await new ApiService().post('admin/partner/register', { user });
}
export async function forgotPassword(email: string) {
  return await new ApiService().post('auth/passwordResetRequest', { email });
}

export async function resetPassword(password: string, passwordResetToken?: string) {
  return await new ApiService().post('auth/passwordReset', { password, passwordResetToken });
}

export async function changePassword(password: string, userId?: any) {
  return await new ApiService().post('auth/changePassword', { password, userId });
}

export async function profileUpdate(user: IUser) {
  let ur: IUserRequest = {
    username: user.username,
    userid: user.userid,
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    phoneno: user.phoneno,
    street1: user.street1,
    street2: user.street2,
    city: user.city,
    state: user.state,
    zip: user.zip,
    addresstype: user.addresstype,
    lattitude: "",
    longitude: "",
    usertype: user.usertype
  };
  return await new ApiService().post('user/updateProfile', ur);
}
// export async function profileUpdate(user: IUser) {
//   let ur: IUserRequest = {
//     username: user.username,
//     userid: user.userid,
//     firstname: user.firstname,
//     lastname: user.lastname,
//     email: user.email,
//     phoneno: user.phoneno,
//     street1: user.street1,
//     street2: user.street2,
//     city: user.city,
//     state: user.state,
//     zip: user.zip,
//     addresstype: user.addresstype,
//     lattitude: "",
//     longitude: "",
//     usertype: user.usertype
//   };
//   return await new ApiService().post('user/updateProfile', ur);
// }

export async function profileUpdate_d(email: string, fullname: string, address: string, phone: string) {
  return await new ApiService().post('user/updateProfile', { email, fullname, address, phone });
}
// export async function payeesBulkInsert(recipients: IRecipients) {
//   return await new ApiService().post('/partner/recipientlist/export', { recipients });
// }

export async function createPartner(firstname: string, lastname: string, email: string, phoneno: string, password: string, merchantid: number, merchantregname: string,
  street1: string, street2: string, city: string, state: string, zip: string, lattitude: string, longitude: string) {
  return await new ApiService().post('partner/register', { firstname, lastname, email, phoneno, password, merchantid, merchantregname, street1, street2, city, state, zip, lattitude, longitude });
}

const LoginApiRoutes = {
  loginRoute: () => `auth/login`
};
