import React, { useMemo } from "react";
import { AdminAsideMenuList } from "./AdminAsideMenuList";
import { PartnerAsideMenuList } from "./PartnerAsideMenuList";
import { PayeeAsideMenuList } from "./PayeeAsideMenuList";
import { useHtmlClassService } from "../../../../../_metronic/layout/_core/MetronicLayout";
import { shallowEqual, useSelector } from "react-redux";
import { roles  } from "../../../../enums";

export function AppAsideMenu({ disableScroll }: any) {
  const { userRole } = useSelector(
    ({ auth }: any) => ({
      userRole: auth.user.roleid,
    }),
    shallowEqual
  );

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {userRole == roles.SystemAdmin || userRole == roles.ServiceAdmin ?
          <AdminAsideMenuList layoutProps={layoutProps} />
          :
          (userRole == roles.PartnerAdmin || userRole == roles.PartnerUsers ?
            <PartnerAsideMenuList layoutProps={layoutProps} />
            :
            <PayeeAsideMenuList layoutProps={layoutProps} />
          )
        }
      </div>
      {/* end::Menu Container */}
    </>
  );
}
