import {createSlice} from "@reduxjs/toolkit";

const initialBankcardusersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  bankcarduserForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const bankcardusersSlice = createSlice({
  name: "bankcardusers",
  initialState: initialBankcardusersState,
  reducers: {
    catchError: (state: any, action: any) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state: any, action: any) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCustomerById
    bankcarduserFetched: (state: any, action: any) => {
      state.actionsLoading = false;
      state.bankcarduserForEdit = action.payload.bankcarduserForEdit;
      state.error = null;
    },
    // findBankcardusers
    bankcardusersFetched: (state: any, action: any) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createCustomer
    bankcarduserCreated: (state: any, action: any) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.bankcarduser);
    },
    // updateCustomer
    bankcarduserUpdated: (state: any, action: any) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity: any) => {
        if (entity.id === action.payload.bankcarduser.id) {
          return action.payload.bankcarduser;
        }
        return entity;
      });
    },
    // deleteCustomer
    bankcarduserDeleted: (state: any, action: any) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter((el: any) => el.id !== action.payload.id);
    },
    // deleteBankcardusers
    bankcardusersDeleted: (state: any, action: any) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (el: any) => !action.payload.ids.includes(el.id)
      );
    },
    // bankcardusersUpdateState
    bankcardusersStatusUpdated: (state: any, action: any) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity: any) => {
        if (ids.findIndex((id: any) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
