import axios from 'axios';
import { environment } from "../../environments/environment";
//import Cookies from 'js-cookie';
import { tokenManagement } from "../../redux/tokenManagement";
//import * as auth from "../../app/modules/Auth/_redux/authRedux";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
import store from "../../redux/store";
import { IToken } from "../../app/model";


const bankcardapi = axios.create({
    baseURL: environment.bankCardApiUrl,//process.env.REACT_APP_ANALYTICS_URL,
    headers: {
        'Content-Type': 'application/json'
        , 'Authorization': '',
    }
});

bankcardapi.interceptors.response.use(response => {
    return response;
}, err => {
    // console.log('refresh token errrrrrrrrrrr');
    // console.log(err);
    return new Promise((resolve, reject) => {
        const originalReq = err.config;
        // console.log('err.config');
        // console.log(err.config);
        let tokenMng = new tokenManagement();

        if (tokenMng.isTokenEligibleForRefresh() && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            originalReq._retry = true;

            let oldToken = tokenMng.getTokenModel();

            let res = fetch(environment.bankCardApiUrl + 'auth/refreshToken', {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json',
                    'Device': 'device',
                    'Token': oldToken.authToken
                },
                redirect: 'follow',
                referrer: 'no-referrer',
                body: JSON.stringify({
                    token: oldToken.authToken,
                    userid: oldToken.userid
                }),
            }).then(res => res.json()).then(res => {
                let rep: any = res.message;
                //console.log(rep);
                // response.is_success = rep.success;
                // response.message = rep.message;
                // response.content = rep.content;
                if (rep.success) {
                    let token: IToken = {
                        authToken: rep.content.authToken,
                        expiresIn: rep.content.expiresIn,
                        roleid: rep.content.roleid,
                        userid: rep.content.userid,
                        lastlogindate: oldToken.lastlogindate,
                        loginFailedAttempts: oldToken.loginFailedAttempts,
                    }

                    //Update new token to store and invoke same method again.
                    store.dispatch(auth.actions.refreshToken(token));

                    //Once the store is updated, we have to invoke failed request to get data.
                    return bankcardapi(originalReq);
                }
            });


            resolve(res);
        }


        return Promise.reject(err);
    });
});

bankcardapi.interceptors.request.use(function (config) {
    let tokenMng = new tokenManagement();

    if (tokenMng.isTokenValid()) {
        config.headers.Authorization = tokenMng.getCurrentToken();

        // checkTokenRefreshIsRequired();
    }

    return config;
});

// function checkTokenRefreshIsRequired() {
//     let tokenMng = new tokenManagement();

//     if (tokenMng.isTokenEligibleForRefresh()) {

//         let oldToken = tokenMng.getTokenModel();

//         let res = fetch(environment.bankCardApiUrl + 'auth/refreshToken', {
//             method: 'POST',
//             mode: 'cors',
//             cache: 'no-cache',
//             credentials: 'same-origin',
//             headers: {
//                 'Content-Type': 'application/json',
//                 'Device': 'device',
//                 'Token': oldToken.authToken
//             },
//             redirect: 'follow',
//             referrer: 'no-referrer',
//             body: JSON.stringify({
//                 token: oldToken.authToken,
//                 userid: oldToken.userid
//             }),
//         }).then(res => res.json()).then(res => {
//             let rep: any = res.message;
//             //console.log(rep);
//             // response.is_success = rep.success;
//             // response.message = rep.message;
//             // response.content = rep.content;
//             if (rep.success) {
//                 let token: IToken = {
//                     authToken: rep.content.authToken,
//                     expiresIn: rep.content.expiresIn,
//                     roleid: rep.content.roleid,
//                     userid: rep.content.userid,
//                     lastlogindate: oldToken.lastlogindate,
//                     loginFailedAttempts: oldToken.loginFailedAttempts,
//                 }

//                 //Update new token to store and invoke same method again.
//                 store.dispatch(auth.actions.refreshToken(token));
//             }
//         });
//     }
// }

export default bankcardapi;
