import React from 'react';

type Props = {
    startTimeInSeconds: number;
    history: any
}
type State = {
    timeRemainingInSeconds: number;
    history: any
}
export class CountdownTimer extends React.Component<Props, State> {
    private timer: any;
    private _isMounted = false;

    constructor(props: Props) {
        super(props);
        this.state = {
            timeRemainingInSeconds: props.startTimeInSeconds,
            history: props.history
        };
    }

    decrementTimeRemaining = () => {
        if (this.state.timeRemainingInSeconds > 0) {
            if (this._isMounted) {
                this.setState({
                    timeRemainingInSeconds: this.state.timeRemainingInSeconds - 1
                });
            }
        } else {
            clearInterval(this.timer!);
            this.state.history.push("/logout");
        }
    };

    componentDidMount() {
        this._isMounted = true;
        this.timer = setInterval(() => {
            this.decrementTimeRemaining();
        }, 1000);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <span className="countdown-timer">
                <strong>00:{this.state.timeRemainingInSeconds}</strong>
            </span>
        );
    }
}