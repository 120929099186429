import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import store from "../../../../redux/store";
import { debug } from "console";
import { IToken } from "../../../model";
import { roles } from "../../../enums";
import { storeHelper } from "../../../../redux/storeHelper";
import { partnerService } from "../../Admin/_services/partner.service";
import { any } from "prop-types";
import { call, all } from "typed-redux-saga";
interface IterableIterator<T,TNext = any> { }

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  //Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  RefreshToken: "[RefreshToken] Action",
};

export interface iinitialAuthState {
  user: undefined,
  authToken: undefined
};

const initialAuthState: iinitialAuthState = {
  user: undefined,
  authToken: undefined
};

export const reducer = persistReducer(
  { storage, key: "bankcards-auth", whitelist: ["user", "authToken"] },
  (state: iinitialAuthState = initialAuthState, action: any) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.RefreshToken: {

        //debugger;
        const { authToken } = action.payload;

        return { ...state, authToken};
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken: any) => ({ type: actionTypes.Login, payload: { authToken } }),
  refreshToken: (authToken: any) => ({ type: actionTypes.RefreshToken, payload: { authToken } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user?: any) => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (user: any) => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    //debugger;
    yield put(actions.requestUser());
  });
 yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    //let authToken: any = {};
    const {
      auth: { authToken }
    }: any = store.getState();
    //debugger;
    let loggedInUser = new storeHelper();
    var user: any = {};
    if (authToken?.roleid == roles.PartnerAdmin || authToken?.roleid == roles.PartnerUsers) {
      const req: any = { id: authToken?.userid, usertype: authToken?.roleid };
      //console.log('reqreqreqreq');
      //console.log(req);
      let rUser;
      rUser= yield partnerService.getPartnerById(req);
      user = rUser.content;
    } else {
      user = yield getUserByToken(authToken?.userid);
    }
      yield put(actions.fulfillUser(user));

  });
}
