
import React, { useState } from 'react';
import IdleTimer from 'react-idle-timer';
import { IdleTimeOutModel } from './IdleTimeOutModel';
import { useHistory } from "react-router-dom";
import { environment } from "../../../environments/environment";

export default function IdleSessionOut() {
    const [showModal, setShowModal] = useState(false);
    const history = useHistory();
    let idleTimer: any = null;

    function handleOnAction(event: any) {
    }

    function handleOnActive(event: any) {
    }

    function handleOnIdle(event: any) {
        setShowModal(true);
    }

    function handleClose() {
        setShowModal(false);
    }


    function handleLogout() {
        setShowModal(false);

        history.push("/logout");
    }
    return (
        <>
            <IdleTimer
                ref={ref => { idleTimer = ref }}
                timeout={environment.idealTimeOutError}
                onActive={handleOnActive}
                onIdle={handleOnIdle}
                onAction={handleOnAction}
                debounce={250}
            />
            <IdleTimeOutModel
                showModal={showModal}
                handleClose={handleClose}
                handleLogout={handleLogout}
            />
        </>
    )
}

