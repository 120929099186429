import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { changePassword, resetPassword } from "../_redux/authCrud";


const initialValues = {
  newPassword: "",
  confirmNewPassword: "",
};


function ChangePassword(props: any) {
  let errorMsg: any = "";
  const { intl } = props;
  const [isRequested, setIsRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(errorMsg);
  const [iserror, IsError] = useState(false);
  const [headerTitle, setHeaderTitle] = useState("Change Password");
  const [successFlg, setSuccessFlg] = useState(false);

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };


  const ChangePasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
      confirmNewPassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Password and Confirm Password didn't match"
        ),
      }),
  });

  const getInputClasses = (fieldname: string) => {
    // if (formik.touched[fieldname] && formik.errors[fieldname]) {
    //   return "is-invalid";
    // }

    // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
    //   return "is-valid";
    // }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      //debugger;
      enableLoading();
      let userId = localStorage.getItem('userId');
      changePassword(values.newPassword, userId)
        .then((e) => {
          disableLoading();
          if (e?.is_success) {
            setSuccessFlg(true);
            setMessage("Your password has been changed successfully.");
            var tokenData = localStorage.getItem("firstLoginToken") ?? "";
            var token: any = JSON.parse(tokenData);
            props.login(token);
          }
          else {
            setSuccessFlg(false);
            setMessage(e?.message);
            IsError(true);
          }
        })
        .catch(() => {
          setIsRequested(false);
          setSubmitting(false);
          setStatus(
            intl.formatMessage(
              { id: "AUTH.VALIDATION.NOT_FOUND" }
            )
          );
        });
    },
  });

  return (
    <>
      {isRequested && <Redirect to="/auth" />}
      {!isRequested && (
        <div className="login-form login-signin" id="kt_forgotPassword_form">
          <div className="login-form login-forgot" style={{ display: "block" }}>
            <div className="text-center mb-10 mb-lg-20">
              <h3 className="font-size-h1">{headerTitle} </h3>
              <div className="text-muted font-weight-bold">
                {successFlg ? "" : "set up your new password here "}
                <a></a>
              </div>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
            >
              {formik.status && (
                <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              )}
              <div className={successFlg ? "hide-controls" : "form-group fv-plugins-icon-container"}  >
                <input
                  placeholder="New Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "password"
                  )}`}
                  {...formik.getFieldProps("newPassword")}
                />
                {formik.touched.newPassword && formik.errors.newPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.newPassword}</div>
                  </div>
                ) : null}
              </div>
              {/* end: Password */}

              {/* begin: Confirm Password */}
              <div className={successFlg ? "hide-controls" : "form-group fv-plugins-icon-container"}>
                <input
                  placeholder="Confirm New Password"
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "changepassword"
                  )}`}
                  {...formik.getFieldProps("confirmNewPassword")}
                />
                {formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.confirmNewPassword}
                    </div>
                  </div>
                ) : null}
              </div>
              {/* end: Confirm Password */}
              {iserror ?
                <div className="col-12 alert alert-custom alert-light-danger alert-dismissible">
                  <div className="alert-text font-weight-bold">{message}</div>
                </div>
                :
                <p className='text-green'>{message}</p>
              }


              <div className={successFlg ? "hide-controls" : "form-group d-flex flex-wrap flex-center"}>
                <button
                  id="kt_login_forgot_submit"
                  type="submit"
                  className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  disabled={loading}
                >
                  Submit
                  {loading && <span className="ml-3 spinner spinner-white"></span>}
                </button>
                <Link to="/auth">
                  <button
                    type="button"
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                  >
                    Cancel
                </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default injectIntl(connect(null, auth.actions)(ChangePassword));
