/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../_metronic/_helpers";
import { SvgIcon, Icon } from "@material-ui/core";


export function AdminAsideMenuList({ layoutProps }: any) {
    const location = useLocation();
    const getMenuItemActive = (url: string, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>

                {/*begin::dashboard Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/admindashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/admindashboard">
                        <span className="svg-icon menu-icon">
                            <Icon className="fa fa-home" />
                        </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>
                {/*end::dashboard Level*/}
                {/*begin::Bankcard Users Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/bankcardusermanagement", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/bankcardusermanagement">
                        <span className="svg-icon menu-icon">
                        <Icon className="fa fa-user-plus" aria-hidden="true"/>
                        </span>
                        <span className="menu-text">Bankcard Users</span>
                    </NavLink>
                </li>
                {/*end::Bankcard Users Level*/}
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/partnermanagement", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/partnermanagement">
                        <span className="svg-icon menu-icon">
                        <Icon className="fas fa-handshake"/>
                        </span>
                        <span className="menu-text">Partner Management</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}
                {/*begin::2 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/partnerusers", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/partnerusers">
                        <span className="svg-icon menu-icon">
                            <Icon className="fa fa-users" aria-hidden="true"/>
                        </span>
                        <span className="menu-text">Partner Users</span>
                    </NavLink>
                </li>
                {/*end::2 Level*/}
                {/*begin::notificationhistory Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/notificationhistory", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/notificationhistory">
                        <span className="svg-icon menu-icon">
                            <Icon className="fas fa-chart-bar" />
                        </span>
                        <span className="menu-text">Reports</span>
                    </NavLink>
                </li>
                {/*end::notificationhistory Level*/}
                {/*begin::3 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/profile", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/profile">
                        <span className="svg-icon menu-icon">
                            <Icon className="fa fa-user" />
                        </span>
                        <span className="menu-text">Edit Profile</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
