import { bankcardapi } from "../../config/api";
import { AxiosRequestConfig } from "axios";

export interface ajaxResponse {
  is_success: boolean,
  message: string,
  content: any
}

const response: ajaxResponse = {
  is_success: false,
  message: '',
  content: []
};

export class ApiService {
  public async get(path: string, param: {}) {
    const config: AxiosRequestConfig = {
      params: param
    }

    return await bankcardapi.get(path, config).then(resp => {
      let rep: any = resp.data.message;
      response.is_success = rep.success;
      response.message = rep.message;
      response.content = rep.content;
      return response;
    })
      .catch(error => {
        if (error.response) {
          response.is_success = false;
          response.message = error.response.status + '';
          response.content = [];
          return response;
        }
      });
  }

  public async post(path: string, params: {}) {
    return await bankcardapi.post(path, params).then(resp => {
      let rep: any = resp.data.message;
      response.is_success = rep.success;
      response.message = rep.message;
      response.content = rep.content;
      return response;
    })
      .catch(error => {
        if (error.response) {
          response.is_success = false;
          response.message = error.response.data.message;
          response.content = [];
          return response;
        }
      });
  }
}