/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../_metronic/_helpers";

export function PayeeAsideMenuList({ layoutProps }: any) {
    const location = useLocation();
    const getMenuItemActive = (url: string, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/profile", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/profile">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                        </span>
                        <span className="menu-text">Edit Profile</span>
                    </NavLink>
                </li>
                {/*end::1 Level*/}
            </ul>

            {/* end::Menu Nav */}
        </>
    );
}
