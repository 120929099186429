import { ApiService } from '../../../services/api.service';
import { IPartnerEmployeeRequest, IPartnerUserRequest } from "../../../model"

export const ME_URL = "api/me";

async function createPartner(firstname: string, lastname: string, email: string, phoneno: string, merchantid: number, merchantregname: string,
  street1: string, street2: string, city: string, state: string, zip: string, lattitude: string, longitude: string, addresstype: string, username: string, usertype: string) {
  return await new ApiService().post('partner/register', { firstname, lastname, email, phoneno, merchantid, merchantregname, street1, street2, city, state, zip, lattitude, longitude, addresstype, username, usertype });
}

async function updatePartner(userid: number, firstname: string, lastname: string, email: string, phoneno: string, merchantid: number, merchantregname: string,
  street1: string, street2: string, city: string, state: string, zip: string, lattitude: string, longitude: string, addresstype: string, username: string) {
  return await new ApiService().post('partner/updatePartner', { userid, firstname, lastname, email, phoneno, merchantid, merchantregname, street1, street2, city, state, zip, lattitude, longitude, addresstype, username });
}

async function getPartnerById(req: any) {
  // let adminid = 1;
  //debugger;
  let r: any = {};
  r = await new ApiService().get('partner/getPartnerById', req);
  return r;
  //debugger;    
}
async function getAllPartners() {
  // let adminid = 1;
  debugger;

  alert('is this in user? if yes delete..');

  return await new ApiService().post('partner/getAllPartnerAdmins', {
    "filter": { "fullname": "", "city": "", "zip": "", "phone": "" },
    "pageNumber": "1", "pageSize": "10", "sortField": "id", "sortOrder": "asc"
  });
  //debugger;    
}

async function deletePartner(userid: number) {
  return await new ApiService().post('partner/deletePartner', { userid });
}

async function createPartnerEmployee(employeReq: IPartnerEmployeeRequest) {
  return await new ApiService().post('user/register', employeReq);
}

async function updatePartnerEmployee(employeReq: IPartnerEmployeeRequest) {
  return await new ApiService().post('user/updateProfile', employeReq);
}

async function getUserById(id: any) {
  // let adminid = 1;
  //debugger;
  return await new ApiService().get('user/getUserById', { id });
  //debugger;    
}
// async function getAllPartnerEmployees() {
//   return await new ApiService().post('employee/getAllEmployees', {
//     "filter": { "employeename": "", "city": "", "zip": "", "phone": "", "partnerid": "" }
//   });
// }

async function deletePartnerEmployee(userid: number) {
  return await new ApiService().post('user/deleteUser', { userid });
}

async function getEmployeeById(empid: any) {
  return await new ApiService().get('employee/getEmployeeById', { empid });
}

async function validateUser(username: any) {
  return await new ApiService().get('user/validateUser', { username });
}

// async function createUserByUserType(firstname: string, lastname: string, email: string, phoneno: string, 
//   street1: string, street2: string, city: string, state: string, zip: string, lattitude: string, longitude: string, addresstype: string,username:string,usertype:number) {
//   return await new ApiService().post('user/register', { firstname, lastname, email, phoneno,  street1, street2, city, state, zip, lattitude, longitude, addresstype,username,usertype });
// }

// async function updateUserByUserType(userid: number, firstname: string, lastname: string, email: string, phoneno: string, 
//   street1: string, street2: string, city: string, state: string, zip: string, lattitude: string, longitude: string, addresstype: string,username:string,usertype:number) {
//   return await new ApiService().post('user/updateProfile', { userid, firstname, lastname, email, phoneno,  street1, street2, city, state, zip, lattitude, longitude, addresstype,username,usertype });
// }

async function createPartnerUser(employeReq: IPartnerUserRequest) {
  return await new ApiService().post('partner/register', employeReq);
}
async function updatePartnerUser(employeReq: IPartnerUserRequest) {
  return await new ApiService().post('user/updateProfile', employeReq);
}

async function getPartnersLite() {
  return await new ApiService().get('partner/getPartners', {});
}

async function createSettings(url: string, message: string, tollfreenumber: string) {
  return await new ApiService().post('partner/settings', { url, message, tollfreenumber });
}

export const partnerService = {
  createPartner,
  updatePartner,
  getPartnerById,
  getAllPartners,
  deletePartner,
  createPartnerEmployee,
  updatePartnerEmployee,
  getUserById,
  //getAllPartnerEmployees,
  deletePartnerEmployee,
  getEmployeeById,
  validateUser,
  // createUserByUserType,
  // updateUserByUserType,

  getPartnersLite,
  createPartnerUser,
  updatePartnerUser,
  createSettings
};