/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../../_metronic/_helpers";
import { SvgIcon, Icon } from "@material-ui/core";

export function PartnerAsideMenuList({ layoutProps }: any) {
    const location = useLocation();
    const getMenuItemActive = (url: string, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };
    return (
        <>
            {/* begin::Menu Nav */}
            <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                <li
                    className={`menu-item ${getMenuItemActive("/partnerdashboard", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/partnerdashboard">
                        <span className="svg-icon menu-icon">
                            <Icon className="fa fa-home" />
                        </span>
                        <span className="menu-text">Dashboard</span>
                    </NavLink>
                </li>
                {/* Notification */}
                {/*begin::partnerusers Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/partnerusers", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/partnerusers">
                        <span className="svg-icon menu-icon">
                        <Icon className="fa fa-users" aria-hidden="true"/>
                        </span>
                        <span className="menu-text">Users</span>
                    </NavLink>
                </li>
                {/*end::partnerusers Level*/}

                {/*begin::1 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/notifications", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/notifications">
                        <span className="svg-icon menu-icon">
                        <Icon className="fa fa-upload" aria-hidden="true"/>
                        </span>
                        <span className="menu-text">Upload Data</span>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Upload Data</span>
                                </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item ${getMenuItemActive("/employeedataupload", false)}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/employeedataupload">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Employee(s)</span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item ${getMenuItemActive(
                                    "/payeedataupload"
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/payeedataupload">
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">Payee(s)</span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}
                {/*begin::Employee Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/partner/employeenotifications", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/partner/employeenotifications">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
                        </span>
                        <span className="menu-text">Employee Notifications</span>
                    </NavLink>
                </li>
                {/*end::Employee Level*/}
                {/*begin::1 Level*/}
                <li
                    className={`menu-item menu-item-submenu ${getMenuItemActive(
                        "/notifications", true
                    )}`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                >
                    <NavLink className="menu-link menu-toggle" to="/notifications">
                        <span className="svg-icon menu-icon">
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
                        </span>
                        <span className="menu-text">Payee Notifications</span>
                    </NavLink>
                    <div className="menu-submenu">
                        <i className="menu-arrow" />
                        <ul className="menu-subnav">
                            <li className="menu-item menu-item-parent" aria-haspopup="true">
                                <span className="menu-link">
                                    <span className="menu-text">Payee Notifications</span>
                                </span>
                            </li>
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item ${getMenuItemActive(
                                    "/payeenotificationmanagement"
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/payeenotificationmanagement">
                                    <span className="svg-icon menu-icon">
                                    <Icon className="fas fa-bell"/>
                                    </span>
                                    <span className="menu-text">General Notifications</span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                            {/*begin::2 Level*/}
                            <li
                                className={`menu-item ${getMenuItemActive(
                                    "/payeenotifications"
                                )}`}
                                aria-haspopup="true"
                            >
                                <NavLink className="menu-link" to="/payeenotifications">
                                    <span className="svg-icon menu-icon">
                                        <Icon className="fas fa-credit-card"/>
                                    </span>
                                    <span className="menu-text">Payment Notifications</span>
                                </NavLink>
                            </li>
                            {/*end::2 Level*/}
                        </ul>
                    </div>
                </li>
                {/*end::1 Level*/}
                {/*begin::notificationhistory Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/notificationhistory", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/notificationhistory">
                        <span className="svg-icon menu-icon">
                            <Icon className="fas fa-chart-bar"/>
                        </span>
                        <span className="menu-text">Reports</span>
                    </NavLink>
                </li>
                {/*end::notificationhistory Level*/}
                {/*begin::1 Level*/}
                <li
                    className={`menu-item ${getMenuItemActive("/profile", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/profile">
                        <span className="svg-icon menu-icon">
                            <Icon className="fa fa-user" aria-hidden="true"/>
                        </span>
                        <span className="menu-text">Edit Profile</span>
                    </NavLink>
                </li>
                <li
                    className={`menu-item ${getMenuItemActive("/partnersettings", false)}`}
                    aria-haspopup="true"
                >
                    <NavLink className="menu-link" to="/partnersettings">
                        <span className="svg-icon menu-icon">
                            <Icon className="fa fa-cogs" aria-hidden="true"/>
                        </span>
                        <span className="menu-text">Settings</span>
                    </NavLink>
                </li>

                {/*end::1 Level*/}

            </ul>
            {/* end::Menu Nav */}
        </>
    );
}
