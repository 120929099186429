import { environment as dev } from './environment.dev';
import { environment as prod } from './environment.prod';

//debugger;
const host: string = window.location.host.toLowerCase();
let stage = 'default'

switch (host) {
    case 'localhost':
        stage = 'local';
        break;
}

const environments = {
    default: {
        production: false,
        stage: stage,
        //bankCardApiUrl: 'https://api.billtexting.com/', //PROD
        bankCardApiUrl: 'https://devapi.billtexting.com/', //DEV
        //bankCardApiUrl: 'http://localhost:5000/',  //LOCAL
        idealTimeOutError: 1800000, //30*60*1000
    },
    dev: dev,
    prod: prod
}

export const environment = (environments as any)[stage];
