import store from "../redux/store";
import { roles } from "../app/enums";
import { ILoggedInUserInfo } from "../app/model";

export class storeHelper {

    private getLoggedInUserFromStore = (): any => {
        const {
            auth: { user }
        }: any = store.getState();

        return user;
    }

    public getLoggedInUserInfoStore = (): ILoggedInUserInfo => {
        const {
            auth: { user }
        }: any = store.getState();

        return user as ILoggedInUserInfo;
    }

    public getLoggedInUserRole = (): roles => {
        var rl: roles = this.getLoggedInUserFromStore().roleid as roles
        return rl;
    }

    public getLoggedInUserId = (): number => {
        return this.getLoggedInUserFromStore().id
    }


    public getLoggedInUserPartnerAdminId = (): number => {
        return this.getLoggedInUserFromStore().partneradminid
    }
}

export const oStoreHelper = new storeHelper();
