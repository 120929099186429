import { IToken } from "../app/model/tokenModel";
import store from "../redux/store";
import jwtDecode from "jwt-decode";
import { JWTTokenValidateResponse } from "../app/model/jwttokenvalidate";

export class tokenManagement {

    private getTokenFromStore = (): any => {
        const {
            auth: { authToken }
        }: any = store.getState();

        return authToken;
    }

    public getCurrentToken = (): string => {
        const {
            auth: { authToken }
        }: any = store.getState();

        return authToken?.authToken;
    }

    public isTokenValid = (): boolean => {

        let isValid = false;
        try {
            let tokenM: IToken = this.getTokenModel();
            if (tokenM && tokenM.authToken != "") {
                let parseToken = jwtDecode<JWTTokenValidateResponse>(tokenM.authToken.replace("Bearer ", ""));
                isValid = parseToken?.exp > (Date.now() / 1000)
            }
        } catch {
            isValid = false;
        }
        return isValid;
    }

    public isTokenValidForRefresh = (): boolean => {

        let isValid = false;
        try {
            let tokenM: IToken = this.getTokenModel();
            if (tokenM && tokenM.authToken != "") {
                let parseToken = jwtDecode<JWTTokenValidateResponse>(tokenM.authToken.replace("Bearer ", ""));
                let dt = new Date();
                dt.setSeconds(dt.getSeconds() - 10);
                isValid = parseToken?.exp > dt.getTime()
            }
        } catch {
            isValid = false;
        }
        return isValid;
    }

    public getTokenModel = (): IToken => {
        let rawToken: any = this.getTokenFromStore();

        let token: IToken;
        if (rawToken) {
            token = {
                authToken: rawToken.authToken,
                expiresIn: rawToken.expiresIn,
                roleid: rawToken.roleid,
                userid: rawToken.userid,
                lastlogindate: rawToken.lastlogindate,
                loginFailedAttempts: rawToken.loginFailedAttempts
            }
        } else {
            token = { 
                authToken: "",
                expiresIn: "",
                roleid: -1,
                userid: -1
            };
        }

        return token;
    }

    public isTokenEligibleForRefresh = (): boolean => {

        return this.getCurrentToken() != undefined && !this.isTokenValidForRefresh(); //TODO - Here we may need to add logic to validate how old token is?
    }
}