import React, { Suspense, lazy, useState, useEffect } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import IdleSessionOut from "../app/sharedcomponents/sessionout";

import { storeHelper } from "../redux/storeHelper"
import { roles } from "../../src/app/enums";

let loggedInUser = new storeHelper();

const AdminDashboard = lazy(() =>
  import("../app/modules/Admin/dashboard")
);
const PartnerDashboard = lazy(() =>
  import("../app/modules/Partner/dashboard")
);
const BankcardUserManagement = lazy(() =>
  import("../app/modules/Admin/bankcardusers")
);
const PartnerManagement = lazy(() =>
  import("../app/modules/Admin/Partners")
);
// const PartnerEmployeeManagement = lazy(() =>
//   import("../app/modules/Admin/partneremployees")
// );
const PartnerEmployeeManagement = lazy(() =>
  import("../app/modules/Partner/partneremployee")
);
const EditProfile = lazy(() =>
  import("../app/sharedcomponents/profile/ProfilePage")
);
const EmployeeDataUpload = lazy(() =>
  import("../app/modules/Partner/employeedataupload")
);
const PartnerUsers = lazy(() =>
  import("../app/modules/Admin/partnerusers/index")
);
const PayeeDataUpload = lazy(() =>
  import("../app/modules/Partner/payeedataupload")
);
const PayeeNotifications = lazy(() =>
  import("../app/modules/Partner/payeenotifications")
);
const NotificationsHistory = lazy(() =>
  import("../app/modules/Reports/notificationreports")
);
const PayeeNotificationManagement = lazy(() =>
  import("../app/modules/Partner/payeemanagement")
);
const PartnerSettings = lazy(() =>
  import("../app/modules/Partner/settings")
);
const ChangePassword = lazy(() =>
  import("../app/sharedcomponents/profile/ChangePassword")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  let dashbord: string = (loggedInUser.getLoggedInUserRole() == roles.PartnerAdmin || loggedInUser.getLoggedInUserRole() == roles.PartnerUsers) ? "/partnerdashboard" : "/admindashboard";
  
  return (
    <>
      <IdleSessionOut></IdleSessionOut>
      <Suspense fallback={<LayoutSplashScreen />}>
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to={dashbord} />
          }
          {/* <ContentRoute path="/PartnerManagement" component={PartnerManagement}/> */}
          <ContentRoute path="/admindashboard" component={AdminDashboard} />
          <ContentRoute path="/partnerdashboard" component={PartnerDashboard} />
          <ContentRoute path="/bankcardusermanagement" component={BankcardUserManagement} />
          <ContentRoute path="/PartnerManagement" component={PartnerManagement} />

          <ContentRoute path="/partner/employeenotifications" component={PartnerEmployeeManagement} />

          <ContentRoute path="/profile" component={EditProfile} />
          <ContentRoute path="/changepassword" component={ChangePassword} />
          <ContentRoute path="/employeedataupload" component={EmployeeDataUpload} />

          <ContentRoute path="/partnerusers" component={PartnerUsers} />
          <ContentRoute path="/payeedataupload" component={PayeeDataUpload} />
          <ContentRoute path="/payeenotifications" component={PayeeNotifications} />
          <ContentRoute path="/payeenotificationmanagement" component={PayeeNotificationManagement} />

          <ContentRoute path="/notificationhistory" component={NotificationsHistory} />
          <ContentRoute path="/partnersettings" component={PartnerSettings} />

          <Redirect to="error/error-v1" />
        </Switch>
      </Suspense>
    </>
  );
}
