import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { CountdownTimer } from './CountdownTimer';
import { useHistory } from "react-router-dom";

export const IdleTimeOutModel = ({ showModal, handleClose, handleLogout }: any) => {
    const history = useHistory();
    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>You Have Been Idle!</Modal.Title>
            </Modal.Header>
            <Modal.Body>You Will Get Timed Out In <CountdownTimer startTimeInSeconds={59} history={history}></CountdownTimer>.
            You want to stay?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleLogout}>
                    Logout
            </Button>
                <Button variant="primary" onClick={handleClose}>
                    Stay
            </Button>
            </Modal.Footer>
        </Modal>
    )
}